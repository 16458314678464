import React, { useRef, useState } from 'react';
import './Michi.css';
import play1 from '../Assets/play1.png';
import play2 from '../Assets/play2.png';

let data = ["", "", "", "", "", "", "", "", ""];

const Michi = () => {
  let [count, setCount] = useState(0);
  let [lock, setLock] = useState(false);
  let titleRef = useRef(null);
  let box1 = useRef(null);
  let box2 = useRef(null); 
  let box3 = useRef(null);
  let box4 = useRef(null);
  let box5 = useRef(null); 
  let box6 = useRef(null);
  let box7 = useRef(null);
  let box8 = useRef(null); 
  let box9 = useRef(null);

  let box_array = [box1, box2, box3, box4, box5, box6, box7, box8, box9];

  const toggle = (e, num) => {
    if (lock) {
      return;
    }
    if (count % 2 === 0) {
      e.target.innerHTML = `<img src='${play1}'>`;
      data[num] = "x";
      setCount(++count);
    } else {
      e.target.innerHTML = `<img src='${play2}'>`;
      data[num] = "o";
      setCount(++count);
    }
    checkWin();
  };

  const checkWin = () => {
    if (data[0] === data[1] && data[1] === data[2] && data[2] !== "") {
      won(data[2]);
    } else if (data[3] === data[4] && data[4] === data[5] && data[5] !== "") {
      won(data[5]);
    } else if (data[6] === data[7] && data[7] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[0] === data[3] && data[3] === data[6] && data[6] !== "") {
      won(data[6]);
    } else if (data[1] === data[4] && data[4] === data[7] && data[7] !== "") {
      won(data[7]);
    } else if (data[2] === data[5] && data[5] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[0] === data[4] && data[4] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[2] === data[4] && data[4] === data[6] && data[6] !== "") {
      won(data[6]);
    }
  };

  const won = (winner) => {
    setLock(true);
    if (winner === "x") {
      titleRef.current.innerHTML = `Congratulations: <img src=${play1}> Wins`;
    } else {
      titleRef.current.innerHTML = `Congratulations: <img src=${play2}> Wins`;
    }
  };

  const reset = () => {
    setLock(false);
    data = ["", "", "", "", "", "", "", "", ""];
    titleRef.current.innerHTML = 'Michi in <span>React</span>';
    box_array.forEach((e) => {
      e.current.innerHTML = "";
    });
  };

  return (
    <div className='container'>
      <div className='contitle'>
        <h1 className="title" ref={titleRef}>Michi<span>React</span></h1>    
      </div>
      
      <div className="board">
        <div className='row1'>
          <div className='boxes' ref={box1} onClick={(e) => { toggle(e, 0); }}></div>
          <div className='boxes' ref={box2} onClick={(e) => { toggle(e, 1); }}></div>
          <div className='boxes' ref={box3} onClick={(e) => { toggle(e, 2); }}></div>
        </div>
        <div className='row2'>
          <div className='boxes' ref={box4} onClick={(e) => { toggle(e, 3); }}></div>
          <div className='boxes' ref={box5} onClick={(e) => { toggle(e, 4); }}></div>
          <div className='boxes' ref={box6} onClick={(e) => { toggle(e, 5); }}></div>
        </div>
        <div className='row3'>
          <div className='boxes' ref={box7} onClick={(e) => { toggle(e, 6); }}></div>
          <div className='boxes' ref={box8} onClick={(e) => { toggle(e, 7); }}></div>
          <div className='boxes' ref={box9} onClick={(e) => { toggle(e, 8); }}></div>
        </div>
      </div>
      <button className="reset" onClick={reset}>Reset</button>
      <h2 className='end'>Todos los derechos reservados</h2>
      <h2 className='end'>by: Zegarra</h2>
    </div>
    
  );
};

export default Michi;
